import React, { useRef } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useAuth from '~hooks/useAuth'
import useHttp from '~hooks/useHttp'

import * as s from './Hero.module.scss'

const Hero = ({ name, pic, mobile, email, id }) => {
  const { t } = useTranslation()
  const { request } = useHttp()
  const inputFile = useRef(null)
  const { userId } = useAuth()

  const isSameUser = id === userId

  const updateUserPhoto = async (photo) => {
    await request('https://refhelpapp.herokuapp.com/user/updatePic', 'PUT', {
      pic: photo,
      userId: id,
    })
  }

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click()
  }

  const onPhotoUpload = async () => {
    const file = inputFile.current.files[0]
    const data = new FormData()
    data.append('file', file)
    data.append('upload_preset', 'RefHelp_cards')

    const res = await fetch(
      'https://api.cloudinary.com/v1_1/didw9zkq6/image/upload',
      {
        method: 'POST',
        body: data,
      }
    )

    const imgFile = await res.json()

    updateUserPhoto(imgFile.secure_url)
  }

  return (
    <section className={s.hero_wrapper}>
      <Container className={s.hero}>
        <div className={s.hero_profile}>
          <div className={s.hero_pic}>
            {pic ? <img src={pic} alt="user" /> : name && name[0]}
          </div>
          <h2 className={s.hero_name}>{name}</h2>
          <div className={s.hero_rating}>
            {[...Array(5)].map((_, i) => {
              return (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={`s-${i}`}
                  className={s.hero_star}
                />
              )
            })}
          </div>
          {isSameUser && (
            <>
              <Button
                onClick={onButtonClick}
                className={s.hero_btn}
                variant="outline-light"
              >
                {t(`user.btn`)}
              </Button>
              <input
                onChange={onPhotoUpload}
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
              />
            </>
          )}
        </div>

        <div className={s.hero_infoContainer}>
          <div className={s.hero_info}>
            <h3>E-mail</h3>
            <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
              {email}
            </a>
          </div>
          <div className={s.hero_info}>
            <h3>{t(`user.mobile`)}</h3>
            <a href={`tel:${mobile}`}>{mobile}</a>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Hero
