import React from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Cards from '~components/Cards'

import * as s from './UserCards.module.scss'

const UserCards = ({ list }) => {
  const { t } = useTranslation()
  return (
    <section className={s.usercards}>
      <Container className={s.usercards_wrapper}>
        <h2>{t(`user.title`)}</h2>
        <Cards list={list} />
      </Container>
    </section>
  )
}

export default UserCards
