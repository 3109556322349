import React, { useEffect, useState } from 'react'
import Layout from '~components/Layout'
import S from '~components/seo'
import useHttp from '~hooks/useHttp'
import Hero from './component/Hero'
import UserCards from './component/UserCards'

const User = ({ location: { state } }) => {
  const { userId } = state || { userId: '' }

  const [user, setUser] = useState({})
  const [cards, setCards] = useState([])
  const { request } = useHttp()
  const getUser = async () => {
    const newUser = await request(
      `https://refhelpapp.herokuapp.com/user/${userId}`,
      'GET'
    )
    setUser(newUser)
  }

  const getCards = async () => {
    const list = await request(
      `https://refhelpapp.herokuapp.com/cardsByUser/${userId}`,
      'GET'
    )
    setCards(list)
  }

  useEffect(() => {
    if (userId) {
      getUser()
      getCards()
    }
  }, [userId])

  return (
    <Layout>
      {user ? (
        <>
          {' '}
          <S title={user.name} />
          <Hero {...user} id={userId} />
          <UserCards list={cards} />{' '}
        </>
      ) : (
        <div>BB</div>
      )}
    </Layout>
  )
}

export default User
